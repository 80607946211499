<template>
  <div>
    <div v-if="!clubConfig.name">
      <a
        class="btn btn-primary font-weight-bold d-none d-sm-inline"
        :class="this.klass"
        v-on:click="reserveOnDesktopGeneric"
        style="
                    color: black !important;
                    background-color: aquamarine !important;
                    border: 1px solid silver;
                  "
      >
        {{ this.text }}
      </a>

      <a
        class="btn btn-primary font-weight-bold d-inline-xs d-sm-none card-animation-on-hover"
        :class="this.klass"
        style="
          color: black !important;
          background-color: aquamarine !important;
          border: 1px solid silver;
        "
        v-on:click="reserveOnMobileGeneric"
      >
        {{ this.text }}
      </a>
    </div>
    <div v-if="clubConfig.name">
      <a
        href="#"
        :class="this.klass"
        :style="{ backgroundColor: clubConfig.color }"
        class="btn btn-primary font-weight-bold d-none d-sm-inline card-animation-on-hover"
        v-on:click="reserveOnDesktopClub(clubConfig)"
      >
        {{ this.text }}
      </a>

      <a
        href="#"
        :class="this.klass"
        :style="{ backgroundColor: clubConfig.color }"
        class="btn btn-primary font-weight-bold d-inline-xs d-sm-none card-animation-on-hover"
        v-on:click="reserveOnMobileClub(clubConfig)"
      >
        {{ this.text }}
      </a>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2';
// import 'sweetalert2/dist/sweetalert2.min.css';

export default {
  name: 'RsvpViaDialog',
  props: {
    klass: {
      type: String,
      default: 'btn-sm',
    },
    clubConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    text: {
      type: String,
      default: ' 📲 INFO & RESERVATIONS',
    },
    legend: {
      type: String,
      default: null,
    },
    phoneNumber: {
      type: String,
      default: '524421867547'
    },
    message: {
      type: String,
      default: null
    },
    mailAddress: {
      type: String,
      default: null,
    },
    waQR: {
      type: String,
      default: 'https://imgix.cosmicjs.com/c3b60240-f712-11ec-bf2b-e93971fa55b1-image.png'
    },
    smsQR: {
      type: String,
      default: 'https://imgix.cosmicjs.com/140a0b30-f22f-11ec-a2eb-c1653f3f9199-telegram-cloud-photo-size-1-5014946017338042974-x.jpg'
    }
  },
  methods: {
    reserveOnDesktopGeneric() {
      this.rsvpDesktopDialogGeneric();
      // this.onFreeConciergeClick('desktop');
    },
    reserveOnMobileGeneric() {
      this.rsvpMobileDialogGeneric();
    },

    reserveOnDesktopClub(club) {
      this.rsvpDesktopDialogClub(club);
    },
    reserveOnMobileClub(club) {
      this.rsvpMobileDialogForClub(club);
    },
    onFreeConciergeClick(club) {
      window.ga('send', 'event', 'reservations', 'Clubs: Info & Reservations', club, 40);
      this.$rollbar.info('Clubs: Info & Reservations:' + club);
    },

    replaceTag(string, club) {
      return string.replace('{{club}}', club);
    },
    rsvpMobileDialogForClub(clubConfig) {
      let message = this.message || this.replaceTag(
        this.SMS_MESSAGE_CLUB_SPECIFIC,
        clubConfig.name
      );
      let options = Object.assign({}, this.BASE_MODAL, {
        title: '',
        html: `<img src="${clubConfig.logo}" height="60px" style="border: 1px solid black;border-radius: 360px"><br>` + `<br><small>${this.PERSUASIVE_TEXT_FOR_CLUB()}</small>`,
        footer: this.mailFooter(clubConfig),
      });
      Swal.fire(options).then((result) => {
        if (result.isConfirmed) {
          open(`https://wa.me/${this.phoneNumber}?text=${message}`);
          this.onFreeConciergeClick(clubConfig.name + " - wa info");
        } else if (result.isDenied) {
          open(`sms:+${this.phoneNumber}&body=${message}`);
          this.onFreeConciergeClick(clubConfig.name + " - sms info");
        }
      });
    },

    rsvpDesktopDialogClub(clubConfig) {
      let options = Object.assign({}, this.BASE_MODAL, {
        title: '',
        html: `<img src="${clubConfig.logo}" height="60px" style="border: 1px solid black;border-radius: 360px"><br>` + `<br><small>${this.PERSUASIVE_TEXT_FOR_CLUB()}</small>`,
        footer: this.mailFooter(clubConfig),
        background: clubConfig.color,
      });
      Swal.fire(options).then((result) => {
        if (result.isConfirmed) {
          this.rsvpDesktopWhatsQR(clubConfig.name);
          this.onFreeConciergeClick(clubConfig.name + " - wa qr");
        } else if (result.isDenied) {
          this.rsvpDesktopSmsQR(clubConfig.name);
          this.onFreeConciergeClick(clubConfig.name + " - sms qr");
        }
      });
    },

    rsvpMobileDialogGeneric() {
      let options = Object.assign({}, this.BASE_MODAL, {
        html: `<img src="${this.genericImg}" height="100px" width="250px"><br>`
          + `<br><p>${this.PERSUASIVE_TEXT_GENERIC()}</p>`,
        footer: this.mailFooter({name: 'clubs'}),
      });
      Swal.fire(options).then((result) => {
        if (result.isConfirmed) {
          this.onFreeConciergeClick("wa clubs info");
          open(
            `https://wa.me/${this.phoneNumber}?text=${this.message || this.SMS_MESSAGE_GENERIC}`
          );
        } else if (result.isDenied) {
          this.onFreeConciergeClick("sms clubs info");
          open(`sms:+${this.phoneNumber}&body=${this.message || this.SMS_MESSAGE_GENERIC}`);
        }
      });
    },

    rsvpDesktopDialogGeneric() {
      let options = Object.assign({}, this.BASE_MODAL, {
        html: `<img src="${this.genericImg}" height="100px" width="250px"><br>`
          + `<br><p>${this.PERSUASIVE_TEXT_GENERIC()}</p>`,
        footer: this.mailFooter({name: 'clubs'}),
      });
      Swal.fire(options).then((result) => {
        if (result.isConfirmed) {
          this.onFreeConciergeClick("wa clubs qr");
          this.rsvpDesktopWhatsQR();
        } else if (result.isDenied) {
          this.onFreeConciergeClick("sms clubs qr");
          this.rsvpDesktopSmsQR();
        }
      });
    },
    rsvpDesktopShowQR(club, qrImageUrl) {
      let options = Object.assign({}, this.BASE_MODAL, {
        html: `<p>Scan the QR and send us a message with your inquiry. We'll be glad to help.</p> `,
        imageWidth: 270,
        imageHeight: 270,
        showDenyButton: false,
        showConfirmButton: false,
        footer:
          '<small > We operate from 9AM - 10PM / Answer within 15 mins</small>',
        imageUrl: qrImageUrl,
        imageAlt: 'QR for Contact',
      });
      Swal.fire(options).then((result) => {
      });
    },
    rsvpDesktopWhatsQR(club) {
      this.rsvpDesktopShowQR(
        club,
        this.waQR
      );
    },
    rsvpDesktopSmsQR(club) {
      this.rsvpDesktopShowQR(
        club,
        this.smsQR
      );
    },
    PERSUASIVE_TEXT_FOR_CLUB() {
      Array.prototype.random = function () {
        return this[Math.floor(Math.random() * this.length)];
      };
      return this.legend || this.PERSUASIVE_TEXT.random();
    },
    PERSUASIVE_TEXT_GENERIC() {
      Array.prototype.random = function () {
        return this[Math.floor(Math.random() * this.length)];
      };
      return this.legend || this.PERSUASIVE_TEXT.random();
    },
    mailto(club) {
      return this.mailAddress ? `mailto:${this.mailAddress}` : `mailto:bookings+${
        club.name.toLowerCase().replaceAll("@", "").replaceAll("|", "").replaceAll(/\s/g, '-') || ''
      }@tulum.party`
    },
    mailFooter(club) {
      return `<div class="text-center">
          <br>
          <a href="${this.mailto(club)}" class="btn btn-sm btn-primary text-center" > via email</a>
          <br>
          <br>
          <small>We operate from 9AM - 10PM / Answer within 15 mins</small>
        </div>`;
    },
  },

  data: () => {
    const TITLE =
      '<span style="font-weight: bold;font-size: 16px;color: #efefef;letter-spacing: 5px;font-weight: bolder">TULUM<span style="color:mediumaquamarine;font-size: 33px" >.</span>PARTY</span>\n';
    return {
      genericImg: 'https://imgix.cosmicjs.com/6d22b020-928c-11ed-93ee-cb9a2cd68754-image.png?w=250&h=100&fit=clamp',
      BASE_MODAL: {
        backdrop: true,
        html: '',
        showCloseButton: true,
        title: TITLE,
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: 'WhatsApp',
        denyButtonText: `SMS`,
        imageWidth: 250,
        imageHeight: 250,
        confirmButtonColor: 'black',
        denyButtonColor: 'black',
        imageAlt: 'Custom image',
        background: '#000000',

      },
      PERSUASIVE_TEXT_CLUBS: [
        "Unlock The Magic - Book Now!",
        "Discover the best of Tulum -  Book Now!",
        'Any questions? Get recommendations and support from our Concierge team.',
        "Unlock The Magic - Secure your spot now",
      ],
      PERSUASIVE_TEXT: [
        "Data-driven choices, we know the scene inside out. Ask our special agents!",
        "No extra fees, no hidden costs to weigh you down. Book through us!",
        "In the world of your queries, we'll play our part. Just drop us a message",
        "Unlock The Magic - Secure your spot with us now!",
        'We\'ve got your back from start to finish.',
      ],
      SMS_MESSAGE_GENERIC: 'Hey there, how can we help you today?',
      SMS_MESSAGE_CLUB_SPECIFIC:
        'RSVP:%20{{club}}%0a--------%0aFULL_NAME:%20%0aDATE:%20%0aARRIVAL_TIME:%20%0aGROUP_SIZE:%20',
    };
  },
};
</script>
<style scoped>
p,
h1,
h2,
h3,
h4 {
  color: black !important;
}

button {
  color: black !important;
}
</style>
